@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Extrabold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Dela Gothic One";
  src: url("./assets/fonts/DelaGothicOne-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat Alternates";
  src: url("./assets/fonts/MontserratAlternates-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Gilroy", sans-serif;
  font-weight: 300;
  overflow-x: hidden;
}

b {
  font-weight: 700;
}

/* Swiper */
.swiper .swiper-button-next,
.swiper .swiper-button-prev {
  @apply text-gray-800;
}
.swiper .swiper-button-next.swiper-button-disabled,
.swiper .swiper-button-prev.swiper-button-disabled {
  display: none;
}

.events-slider .swiper .swiper-button-next,
.events-slider .swiper .swiper-button-prev {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  outline: 0;
  background-color: #222;
  background-repeat: no-repeat;
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 24%);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out,
    opacity 0.2s ease-in-out;
  @apply text-white;
}
.events-slider .swiper .swiper-button-next::after,
.events-slider .swiper .swiper-button-prev::after {
  font-size: 24px;
}

.events-slider .swiper .swiper-button-next:hover,
.events-slider .swiper .swiper-button-prev:hover {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .events-slider .swiper .swiper-button-next,
  .events-slider .swiper .swiper-button-prev {
    width: 30px;
    height: 30px;
    top: auto;
    bottom: 5px;
  }

  .events-slider .swiper .swiper-wrapper {
    padding-bottom: 40px;
  }

  .events-slider .swiper .swiper-button-next::after,
  .events-slider .swiper .swiper-button-prev::after {
    font-size: 16px;
  }
}

.main__calendar {
  position: relative;
}

@media screen and (max-width: 768px) {
  .mobile-image {
    width: 100%;
    height: auto;
  }
}

.desktop-image {
  max-height: 350px;
}

@media screen and (max-width: 768px) {
  .logo {
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .logo {
    flex-direction: column;
  }
}

.question,
.photo_section {
  padding: 0.5rem;
  margin-top: 12px;
  margin-left: 20px;
}

.question h2 {
  color: rgba(0, 74, 147, 1);
  font-size: 24px;
  font-weight: 700;
  font-family: "Montserrat";
  padding-bottom: 10px;
}

.question,
.photo_section h1 {
  font-size: 32px;
  font-weight: 400;
  font-family: "Dela Gothic One";
  padding-bottom: 15px;
}

.question p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat";
  line-height: 19.5px;
  padding-bottom: 10px;
  padding-right: 70px;
}

@media screen and (max-width: 768px) {
  .question {
    flex-direction: column;
  }
}

.question img {
  margin-left: 15px;
}

.video_section {
  background-color: rgba(229, 45, 55, 1);
  margin-top: 20px;
  padding: 30px;
}

.video_section_text {
  color: rgba(167, 17, 25, 1);
}

.video_section iframe {
  flex-shrink: 0;
  aspect-ratio: 560 / 315;
  margin-right: 30px;
}

@media (max-width: 768px){
  .video_section iframe{
    height: 45%;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .video_text {
    flex-direction: column;
  }
}
.custom_blue {
  color: rgba(0, 74, 147, 1);
}

.custom_red {
  background-color: rgba(229, 45, 55, 1);
}

@media screen and (max-width: 768px) {
  .mobile-menu {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .mobile-navbar {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.hover-fill-blue-400 {
  transition: fill 0.3s ease;
  fill: #b3b3b3;
}

.hover-fill-blue-400:hover {
  fill: rgb(96 165 250);
}

.hover-fill-red {
  transition: fill 0.3s ease;
  fill: #b3b3b3;
}

.hover-fill-red:hover {
  fill: rgba(229, 45, 55, 1);
}

@media (max-width: 720px){
  .nav-item {
    max-width: 70px;
    word-break: break-word;
    white-space: normal;
  }
}